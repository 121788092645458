import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';
@Component({
  selector: 'rcp-side-bar-skeleton',
  standalone: true,
  imports: [SkeletonModule, CommonModule],
  templateUrl: './side-bar-skeleton.component.html',
  styleUrl: './side-bar-skeleton.component.scss',
})
export class SideBarSkeletonComponent {}
