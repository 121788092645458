import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { authConfig } from './auth/auth-config';
import { InboundMetricsComponent } from './components/inbound-metrics/inbound-metrics.component';
import { ClientPortalService } from './services/clientPortalService/client-portal.service';
import { UserDetailsAPIPayload } from './services/clientPortalService/types/types';
import { ChangeAddressFormComponent } from './shared/components/change-address-form/change-address-form.component';
import { ClientWarehouseDropdownComponent } from './shared/components/client-warehouse-dropdown/client-warehouse-dropdown.component';
import { DateSelectorComponent } from './shared/components/date-selector/date-selector.component';
import { GridComponent } from './shared/components/grid/grid.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { SideBarSkeletonComponent } from './shared/skeleton/side-bar-skeleton/side-bar-skeleton.component';
import { SidebarService } from './shared/services/sidebar.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    DateSelectorComponent,
    ButtonModule,
    SkeletonModule,
    GridComponent,
    CardModule,
    ChangeAddressFormComponent,
    OverlayPanelModule,
    ClientWarehouseDropdownComponent,
    ToastModule,
    RouterModule,
    InboundMetricsComponent,
    SidebarComponent,
    SideBarSkeletonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'rcp-frontend-v2';
  oAuthService = inject(OAuthService);
  cpService = inject(ClientPortalService);
  router = inject(Router);
  destroyRef = inject(DestroyRef);
  location = window.location;
  loggedInUserInfo: any;
  showHeader = false;
  userDetails: any;
  sitesFetched = false;
  userDetailsFetched = false;
  redirectToDashboard = 0;
  //constants used in html
  welcome = 'Welcome, ';
  directToBaseURL = 0;
  clientName = 0;
  constructor(public sidebarService: SidebarService) {
    this.initOAuth();
  }
  initOAuth(): void {
    this.oAuthService.configure(authConfig);
    this.oAuthService.setStorage(localStorage);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(
      () => {
        if (this.oAuthService.hasValidAccessToken()) {
          this.initAppFlow();
        } else {
          this.oAuthService.initImplicitFlow();
        }
      },
      (e) => {
        this.error(e);
      }
    );
  }
  initAppFlow(): void {
    this.oAuthService.loadUserProfile().then(
      (userInfo: { info: any }) => {
        const { companyName, entryUUID, firstName, lastName, mail } =
          userInfo.info;
        this.loggedInUserInfo = {
          companyName,
          entryUUID,
          firstName,
          lastName,
          mail,
        };
        this.getWareHousesList();
        this.getUserDetails();
      },
      (e) => {
        this.error(e);
      }
    );
  }
  getWareHousesList() {
    this.cpService.getWarehouses().subscribe({
      next: (data: any) => {
        const response = data?.response;
        this.cpService.allClientWareHouses$.next(response);
      },
      error: (e) => {
        this.error(e);
      },
      complete: () => {
        this.sitesFetched = true;
      },
    });
  }
  getUserDetails() {
    const UUID = this.loggedInUserInfo.entryUUID;
    const payLoad: UserDetailsAPIPayload = {
      company_name: '',
      uuid: UUID,
      search_uuid: UUID,
    };
    this.showHeader = false;
    this.cpService
      .getUserDetails(payLoad)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: any) => {
          this.userDetails = data.response;
          if (data.statusmessage === 'Success' && this.userDetails.length > 0) {
            this.storeLoggedInUserInfo(
              this.userDetails[0].uuid,
              this.userDetails[0].role_name,
              this.userDetails[0].role_acronym
            );
            this.cpService.processUserDetailsAPIResponse(this.userDetails);
            this.validateClientSession(
              this.userDetails[0].client_acronym.trim()
            );
            sessionStorage.setItem(
              'companyname',
              this.userDetails[0].client_acronym.trim()
            );
            this.cpService.logInUserDetails$.next(data.response);
          } else if (
            (data.response.errorCode === 'Err_1006' &&
              data.statusmessage === 'Fail') ||
            data.response.length === 0
          ) {
            this.router.navigate(['no-access']);
          }
          this.showHeader = true;
        },
        error: (e) => {
          console.log('error while calling user details api ', e);
          this.error(e);
        },
        complete: () => {
          this.userDetailsFetched = true;
        },
      });
  }
  storeLoggedInUserInfo(UUID: string, rol: string, roleAcnym: string): void {
    this.loggedInUserInfo.UUID = UUID;
    this.loggedInUserInfo.role = rol;
    this.loggedInUserInfo.role_acc = roleAcnym;
    sessionStorage.setItem('uuid', UUID);
    this.cpService.setLoggedInUserInfo(this.loggedInUserInfo);
  }
  onClientWarehouseChange(e: { clientName: string; warehouseList: string[] }) {
    const { clientName: newClient, warehouseList } = e;
    const currentActiveClient = this.cpService.getActiveClient();
    this.cpService.currentSelectedClient$.next(newClient);
    this.cpService.currentlySelectedWarehouses$.next(warehouseList);
    this.validateClientSession(newClient);
    sessionStorage.setItem('companyname', newClient);
    sessionStorage.setItem('selectedWareHouses', warehouseList.toString());
    console.log('this.clientName', this.clientName);
    this.sidebarService.passClientName(true);
    /**
     * currently whenever client changes we route user to
     * first module he is having access to
     * but for time being we will route to '/' but
     * as soon as header is integrated we will route to
     * first module
     */
    if (currentActiveClient !== newClient) {
      this.redirectToDashboard++;
      this.router.navigate(['/']);
    }
  }
  error(e: any) {
    console.error('error occured ', e);
    this.logOut();
    this.router.navigate(['no-access']);
    this.showHeader = true;
  }
  logOut() {
    this.oAuthService.revokeTokenAndLogout();
  }
  navigateToHomePage() {
    const routeUrl = this.cpService.getFirstRouteByActiveClient();
    if (routeUrl !== '') {
      this.router.navigate([routeUrl]);
    } else {
      console.error('unable to get home url for active client');
    }
  }
  /**
   * below function will remove session storage data except uuid when ever stored client does not
   * matched with active client (i.e first client we are getting from userDetails api)
   */
  validateClientSession(client: string): void {
    const storedClient = sessionStorage.getItem('companyname') || '';
    if (storedClient !== client) {
      console.warn(
        `session storage is cleared as new client "${client}" is different from old client "${storedClient}"`
      );
      const keysToRemove = [];
      for (let i = 0; i < sessionStorage.length; i++) {
        const sessionKey = sessionStorage.key(i);
        if (sessionKey !== 'uuid') {
          keysToRemove.push(sessionKey);
        }
      }
      keysToRemove.forEach((key) => {
        sessionStorage.removeItem(key);
      });
    }
  }
}
