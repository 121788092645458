import { Routes } from '@angular/router';
import {
  isChildRouteAllowed,
  isFeatureAllowed,
  isRouteNavigationAllowed,
} from './auth/auth-guard/auth-guard';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { CpRoutes } from './cp-route-constants';
export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: CpRoutes.RealtimeDashboard,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import(
            './components/realtime-dashboard/realtime-dashboard.component'
          ).then((c) => c.RealtimeDashboardComponent),
      },
      {
        path: 'auth',
        redirectTo: '',
      },
      {
        path: CpRoutes.ReturnDetails,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import('./components/return-metrics/return-metrics.component').then(
            (c) => c.ReturnMetricsComponent
          ),
      },
      {
        path: CpRoutes.RealtimeDashboard,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import(
            './components/realtime-dashboard/realtime-dashboard.component'
          ).then((c) => c.RealtimeDashboardComponent),
      },
      {
        path: CpRoutes.ReportBuilder,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import('./components/report-builder/report-builder.component').then(
            (c) => c.ReportBuilderComponent
          ),
      },
      {
        path: CpRoutes.OntimeShipSLA,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import('./components/sla/summery-view/sla.component').then(
            (c) => c.SlaComponent
          ),
      },
      {
        path: CpRoutes.OntimeShipSLAList,
        canActivate: [isChildRouteAllowed],
        loadComponent: () =>
          import('./components/sla/list-view/list-view.component').then(
            (c) => c.ListViewComponent
          ),
        data: {
          parent_route: CpRoutes.OntimeShipSLA,
        },
      },
      {
        path: CpRoutes.HistoricDashboard,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import(
            './components/historic-dashboard/historic-dashboard.component'
          ).then((c) => c.HistoricDashboardComponent),
      },
      {
        path: CpRoutes.InventoryDetails,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import(
            './components/inventory-details/inventory-details.component'
          ).then((c) => c.InventoryDetailsComponent),
      },
      {
        path: CpRoutes.InventoryDetailsInfo,
        canActivate: [isChildRouteAllowed],
        loadComponent: () =>
          import(
            './components/inventory-details/inventory-detail-info/inventory-detail-info.component'
          ).then((c) => c.InventoryDetailInfoComponent),
        data: {
          parent_route: CpRoutes.InventoryDetails,
        },
      },
      {
        path: CpRoutes.PurchaseOrderDetails,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import('./components/inbound-metrics/inbound-metrics.component').then(
            (c) => c.InboundMetricsComponent
          ),
      },
      {
        path: CpRoutes.OrderDetails,
        canActivate: [isRouteNavigationAllowed],
        loadComponent: () =>
          import(
            './components/outbound-metrics/outbound-metrics.component'
          ).then((c) => c.OutboundMetricsComponent),
      },
      {
        path: CpRoutes.OrderDetailsInfo,
        canActivate: [isChildRouteAllowed],
        loadComponent: () =>
          import(
            './components/outbound-metrics/outbound-order-details/outbound-order-details.component'
          ).then((c) => c.OutboundOrderDetailsComponent),
        data: {
          parent_route: CpRoutes.OrderDetails,
        },
      },
      {
        path: CpRoutes.ChangeAddress,
        canActivate: [isFeatureAllowed],
        loadComponent: () =>
          import(
            './shared/components/change-address-form/change-address-form.component'
          ).then((c) => c.ChangeAddressFormComponent),
        data: {
          acronym: CpRoutes.ChangeAddressAcronym,
        },
      },
      {
        path: CpRoutes.AddItem,
        canActivate: [isFeatureAllowed],
        loadComponent: () =>
          import('./shared/components/add-item/add-item.component').then(
            (c) => c.AddItemComponent
          ),
        data: {
          acronym: CpRoutes.AddItemAcronym,
        },
      },
    ],
  },
  {
    path: 'no-access',
    canActivate: [],
    component: NoAccessComponent,
  },
  {
    path: '**',
    canActivate: [],
    component: NoAccessComponent,
  },
];
