<div class="card flex justify-content-center">
  <p-treeSelect
    class="w-full md:w-20rem"
    containerStyleClass="w-full client-warehouse-dd"
    [(ngModel)]="selectedNodes"
    [options]="nodes"
    display="chip"
    placeholder=" "
    [metaKeySelection]="false"
    selectionMode="checkbox"
    (onNodeSelect)="onNodeSelect($event)"
    (onNodeUnselect)="onNodeUnselect($event)"
    [virtualScroll]="true"
    [virtualScrollItemSize]="46"
    [virtualScrollOptions]="{ scrollHeight: '200px' }"
  >
    <ng-template pTemplate="value">
      {{ getLabel() }}
    </ng-template>
  </p-treeSelect>
</div>
<p-toast position="bottom-left"></p-toast>
