import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { filter, Subject, takeUntil } from 'rxjs';
import { CpRoutes } from '../../../cp-route-constants';
import { ClientPortalService } from '../../../services/clientPortalService/client-portal.service';
import { UserService } from '../../../services/user-service/user.service';
import { SidebarService } from '../../services/sidebar.service';
@Component({
  selector: 'rcp-sidebar',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit, OnChanges {
  @Input() redirectToDashboard: number;
  @Input() clientName: any;
  displayTextAndIcon = true;
  dropDownHeight: any;
  reportingString = 'Reporting';
  realtimeDashboardString = 'Realtime Dashboard';
  historicDashboardString = 'Historic Dashboard';
  reportBuilderString = 'Report Builder';
  inboundString = 'Inbound';
  purchaseOrderDetailsString = 'Purchase Order Details';
  outboundSting = 'Outbound';
  orderDetailsString = 'Order Details';
  onTimeShipSLAString = 'On Time Ship SLA';
  returnsString = 'Returns';
  returnDetailsString = 'Return Details';
  inventoryString = 'Inventory';
  inventoryDetailsString = 'Inventory Details';
  listTitle = '.list-title';
  hideTooltip = false;
  sidebarArrowClose = 'Close';
  sidebarArrowOpen = 'Open';
  sidebarclose = 'side-bar-close';
  clientsFromUserDetails: any[];
  selectedCompany: any;
  modulesList: any[];
  permittedRoutes: any[];
  userDetails: any;
  storeFirstURL: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  roleName = '';
  listTitleActive = 'list-title active';
  contentList = '.content li';
  constructor(
    public sidebarService: SidebarService,
    public router: Router,
    public clientPortalService: ClientPortalService,
    public userService: UserService
  ) {}
  ngOnInit(): void {
    this.clientPortalService.logInUserDetails$
      .pipe(
        takeUntil(this.destroy$),
        filter((data) => data?.length > 0)
      )
      .subscribe((data: any) => {
        this.userDetails = data;
        if (this.roleName.length === 0) {
          this.roleName = data[0].role_name;
        }
        this.getClientListFromUserDetails();
      });
    this.sidebarService.changeClientSubject.subscribe((data) => {
      if (data === true) {
        this.getClientListFromUserDetails();
        setTimeout(() => {
          const allLiElement = document.querySelectorAll(this.contentList);
          allLiElement.forEach((allLiElementItems) => {
            allLiElementItems.addEventListener('click', (event) => {
              this.handleLiClick(event);
            });
          });
          const listTitles = document.querySelectorAll(this.listTitle);
          listTitles.forEach((listTitle, index) => {
            listTitle.addEventListener('click', (event) => {
              this.handleClientClick(event);
            });
          });
          this.activeDashboard();
        }, 100);
      }
    });
  }
  handleClientClick(event: Event) {
    const listTitle = event.target as HTMLElement;
    const innerList = listTitle.nextElementSibling as HTMLElement;
    const content = innerList.querySelector('.content');
    const selectFirstElement =
      document.querySelector('.list-title > span').textContent;
    const eventInnerText =
      listTitle.querySelector('.list-title > span').textContent;
    const classNames = listTitle.className;
    if (
      selectFirstElement !== eventInnerText &&
      classNames === 'list-title' &&
      this.roleName !== 'ADMIN'
    ) {
      this.displayTextAndIcon = true;
      this.hideTooltip = false;
      const allActive = document.querySelectorAll(this.listTitle);
      allActive.forEach((item) => {
        if (item.className === this.listTitleActive) {
          item.classList.remove('active');
          const contentLiHeight = item.nextElementSibling as HTMLElement;
          contentLiHeight.style.height = '0';
        }
      });
      listTitle.classList.add('active');
      const contentLiList = content.querySelectorAll('li');
      switch (contentLiList.length) {
        case 1:
          innerList.style.height = '40px';
          break;
        case 2:
          innerList.style.height = '80px';
          break;
        case 3:
          innerList.style.height = '120px';
          break;
        case 4:
          innerList.style.height = '160px';
          break;
        default:
          innerList.style.height = '200px';
      }
    } else {
      if (
        listTitle.classList.contains('active') &&
        selectFirstElement !== eventInnerText &&
        this.roleName !== 'ADMIN'
      ) {
        listTitle.classList.remove('active');
        innerList.style.height = '0';
      }
    }
  }
  getClientListFromUserDetails() {
    this.clientsFromUserDetails =
      this.clientPortalService.getAllowedClientsForLoggedInUser();
    this.selectedCompany = sessionStorage.getItem('companyname')
      ? sessionStorage.getItem('companyname')
      : this.clientsFromUserDetails[0].client_acronym;
    this.getModulesByClient(this.selectedCompany);
  }
  getModulesByClient(client: string) {
    this.selectedCompany = client;
    this.modulesList = this.clientPortalService.getModulesByClient(client);
    this.permittedRoutes = this.clientPortalService.getPermittedRoute(client);
    this.userService.selectedClientInHeader.next(client);
    this.clientPortalService.currentSelectedClient$.next(client);
    this.clientPortalService.logInUserModules.next(this.modulesList);
    this.modulesList = this.clientPortalService.addConfigMenuOptions(
      this.modulesList
    );
    this.clientPortalService.permittedMenuItems = this.permittedRoutes;
    this.modulesList.forEach((item: any, index) => {
      if (index === 0) {
        this.storeFirstURL = item.routing_url;
        this.router.navigateByUrl(this.storeFirstURL);
      }
    });
    this.initialCall();
  }
  initialCall() {
    if (this.modulesList?.length >= 0) {
      this.router.events.subscribe((event: any) => {
        if (event.url === '/') {
          this.displayTextAndIcon = true;
          this.hideTooltip = false;
          event.url = this.storeFirstURL;
          this.router.navigateByUrl(this.storeFirstURL);
          this.modifyURL(event.url);
        } else {
          this.displayTextAndIcon = true;
          this.hideTooltip = false;
          this.modifyURL(event.url);
        }
      });
      this.sidebarService.moduleNameSubject.subscribe((data) => {
        this.modifyURL(data);
      });
    }
  }
  modifyURL(data: any) {
    if (data) {
      const modify = data.replace(/-/g, ' ').split('/');
      const lastString = modify[modify.length - 1];
      this.checkedRoutingURL(lastString.trim());
    }
  }
  checkedRoutingURL(data: any) {
    const allActive = document.querySelectorAll(this.listTitle);
    allActive.forEach((item) => {
      if (item.className === this.listTitleActive) {
        item.classList.remove('active');
        const contentLiHeight = item.nextElementSibling as HTMLElement;
        contentLiHeight.style.height = '0';
      }
    });
    const allLiElement = document.querySelectorAll('li');
    allLiElement.forEach((item) => {
      item.classList.remove('addActiveColor');
    });
    const contentLiList = document.querySelectorAll(this.contentList);
    contentLiList.forEach((item) => {
      if (item.innerHTML.trim().toLocaleLowerCase() === data) {
        const parentElement = item.parentElement.parentElement
          .previousSibling as HTMLElement;
        parentElement.classList.add('active');
        item.classList.add('addActiveColor');
        const contentLiHeight = parentElement.nextElementSibling as HTMLElement;
        switch (item.parentElement.children.length) {
          case 1:
            contentLiHeight.style.height = '40px';
            break;
          case 2:
            contentLiHeight.style.height = '80px';
            break;
          case 3:
            contentLiHeight.style.height = '120px';
            break;
          case 4:
            contentLiHeight.style.height = '160px';
            break;
          default:
            contentLiHeight.style.height = '200px';
        }
      }
    });
  }
  ngOnChanges() {
    if (this.modulesList?.length >= 0) {
      if (this.redirectToDashboard >= 1) {
        this.commonCodeOnchanges();
        const allLiElement = document.querySelectorAll('li');
        allLiElement.forEach((item) => {
          item.classList.remove('addActiveColor');
        });
        this.activeDashboard();
        if (this.displayTextAndIcon === false) {
          this.commonCodeOnchanges();
        } else {
          this.dataHeightCalculated();
        }
      }
    }
  }
  commonCodeOnchanges() {
    const allActive = document.querySelectorAll(this.listTitle);
    allActive.forEach((item) => {
      if (item.className === this.listTitleActive) {
        item.classList.remove('active');
        const contentLiHeight = item.nextElementSibling as HTMLElement;
        contentLiHeight.style.height = '0';
      }
    });
  }
  dataHeightCalculated() {
    const allActive = document.querySelectorAll(this.listTitle);
    allActive.forEach((item) => {
      if (item.className === this.listTitleActive) {
        const contentLiHeight = item.nextElementSibling as HTMLElement;
        const contentLiList = contentLiHeight.querySelectorAll('li');
        switch (contentLiList.length) {
          case 1:
            contentLiHeight.style.height = '40px';
            break;
          case 2:
            contentLiHeight.style.height = '80px';
            break;
          case 3:
            contentLiHeight.style.height = '120px';
            break;
          case 4:
            contentLiHeight.style.height = '160px';
            break;
          default:
            contentLiHeight.style.height = '200px';
        }
      }
    });
  }
  activeDashboard() {
    const allActive = document.querySelector(this.listTitle);
    allActive.classList.add('active');
    const content = document.querySelector('.content');
    const firstLi = document.querySelector(this.contentList);
    firstLi.classList.add('addActiveColor');
    const nextElement = allActive.nextElementSibling as HTMLElement;
    nextElement.style.height = `${content.clientHeight + 5}px`;
    this.dropDownHeight = `${content.clientHeight + 5}px`;
  }
  ngAfterViewInit() {
    this.activeDashboard();
    const listTitles: NodeListOf<HTMLElement> = document.querySelectorAll(
      this.listTitle
    );
    const allLiElement = document.querySelectorAll(this.contentList);
    allLiElement.forEach((allLiElementItems) => {
      allLiElementItems.addEventListener('click', (event) =>
        this.handleLiClick(event)
      );
    });
    listTitles.forEach((listTitle) => {
      listTitle.addEventListener('click', (event) => this.handleClick(event));
    });
  }
  public handleLiClick(event: Event): void {
    const allLiElement = document.querySelectorAll('li');
    allLiElement.forEach((item) => {
      item.classList.remove('addActiveColor');
    });
    const addActiveColor = event.target as HTMLElement;
    addActiveColor.classList.add('addActiveColor');
    const parentElement = addActiveColor.parentElement;
    const parentElementHeight = `${parentElement.clientHeight}px`;
    this.dropDownHeight = parentElementHeight;
    const sidebars = document.querySelectorAll('.app-sidebar');
    setTimeout(() => {
      sidebars.forEach((sidebar) => {
        if (this.displayTextAndIcon === true) {
          this.displayTextAndIcon = false;
          sidebar.classList.toggle(this.sidebarclose);
          const allActive = document.querySelectorAll(this.listTitle);
          const content: NodeListOf<HTMLElement> =
            document.querySelectorAll('.visible-side-bar');
          content.forEach((item) => {
            item.style.height = '0';
          });
          allActive.forEach((item) => {
            if (item.className === this.listTitleActive) {
              item.classList.remove('active');
              this.hideTooltip = true;
            }
          });
        }
      });
    }, 50);
  }
  public handleClick(event: Event): void {
    const listTitle = event.target as HTMLElement;
    const innerList = listTitle.nextElementSibling as HTMLElement;
    const content = innerList.querySelector('.content');
    if (listTitle.classList.contains('active')) {
      listTitle.classList.remove('active');
      innerList.style.height = '0';
    } else {
      this.displayTextAndIcon = true;
      this.hideTooltip = false;
      const allActive = document.querySelectorAll(this.listTitle);
      allActive.forEach((item) => {
        if (item.className === this.listTitleActive) {
          item.classList.remove('active');
          const contentLiHeight = item.nextElementSibling as HTMLElement;
          contentLiHeight.style.height = '0';
        }
      });
      listTitle.classList.add('active');
      const contentLiList = content.querySelectorAll('li');
      switch (contentLiList.length) {
        case 1:
          innerList.style.height = '40px';
          break;
        case 2:
          innerList.style.height = '80px';
          break;
        case 3:
          innerList.style.height = '120px';
          break;
        case 4:
          innerList.style.height = '160px';
          break;
        default:
          innerList.style.height = '200px';
      }
    }
  }
  slideClose(event?: any, flag?: any) {
    const sidebars = document.querySelectorAll('.app-sidebar');
    sidebars.forEach((sidebar) => {
      if (this.displayTextAndIcon === true) {
        this.displayTextAndIcon = false;
        sidebar.classList.toggle(this.sidebarclose);
        this.listCommonCode();
        const allActive = document.querySelectorAll(this.listTitle);
        const content: NodeListOf<HTMLElement> =
          document.querySelectorAll('.visible-side-bar');
        content.forEach((item) => {
          item.style.height = '0';
        });
        allActive.forEach((item) => {
          if (item.className === this.listTitleActive) {
            item.classList.remove('active');
            this.hideTooltip = true;
          }
        });
      } else {
        this.displayTextAndIcon = true;
        this.hideTooltip = false;
        sidebar.classList.toggle(this.sidebarclose);
        this.listCommonCode();
      }
    });
    this.dataHeightCalculated();
  }
  listCommonCode() {
    const allLiElement = document.querySelectorAll('li');
    allLiElement.forEach((item) => {
      if (item.classList.contains('addActiveColor')) {
        const parentElement = item.parentElement.parentElement
          .previousSibling as HTMLElement;
        parentElement.classList.add('active');
        const contentHeight = item.parentElement.parentElement;
        contentHeight.style.height = this.dropDownHeight;
      }
    });
  }
  goToRealtimedashoard() {
    this.router.navigate([CpRoutes.RealtimeDashboard]);
  }
  goToHistoricDashboard() {
    this.router.navigate([CpRoutes.HistoricDashboard]);
  }
  goToReportBuilder() {
    this.router.navigate([CpRoutes.ReportBuilder]);
  }
  goToInbound() {
    this.router.navigate([CpRoutes.PurchaseOrderDetails]);
  }
  goToOrderDetails() {
    this.router.navigate([CpRoutes.OrderDetails]);
  }
  goToSlaSummeryView() {
    this.router.navigate([CpRoutes.OntimeShipSLA]);
  }
  goToReturn() {
    this.router.navigate([CpRoutes.ReturnDetails]);
  }
  goToInventory() {
    this.router.navigate([CpRoutes.InventoryDetails]);
  }
}
