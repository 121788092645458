import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TreeSelectModule } from 'primeng/treeselect';
import { ClientPortalService } from '../../../services/clientPortalService/client-portal.service';
import { SnackBarService } from '../../../services/snackBar-services/snack-bar.service';
declare module 'primeng/api' {
  interface TreeNode<T> {
    dType: 'client' | 'warehouse';
  }
}
@Component({
  selector: 'rcp-client-warehouse-dropdown',
  standalone: true,
  imports: [TreeSelectModule, FormsModule, CommonModule, ToastModule],
  templateUrl: './client-warehouse-dropdown.component.html',
  styleUrl: './client-warehouse-dropdown.component.scss',
})
export class ClientWarehouseDropdownComponent implements OnInit {
  @Output() clientWarehouseChange: EventEmitter<{
    clientName: string;
    warehouseList: string[];
  }> = new EventEmitter<{ clientName: string; warehouseList: string[] }>();
  nodes!: TreeNode<string>[];
  clientPortalServices = inject(ClientPortalService);
  snackBarservice = inject(SnackBarService);
  selectedNodes: TreeNode<string>[];
  allclientData: any[];
  constructor() {
    this.selectedNodes = [];
  }
  ngOnInit(): void {
    this.clientPortalServices.logInUserDetails$.subscribe((userDetails) => {
      if (userDetails?.length === 0) {
        return;
      }
      this.clientPortalServices.allClientWareHouses$.subscribe(
        (data: any[]) => {
          this.allclientData = data;
          this.nodes = this.transformData(userDetails, this.allclientData);
          if (this.nodes?.length > 0) {
            this.selectedNodes = [this.nodes[0], ...this.nodes[0].children];
            // setting initial selecte client and warehosues
            this.setClientNWarehouses();
          } else {
            console.error('unable to generate warehouse dropdown nodes');
          }
        }
      );
    });
  }
  checkSelection() {
    const allNodesUnchecked = this.selectedNodes.length === 0;
    if (allNodesUnchecked) {
      this.snackBarservice.showError(
        'No warehouse',
        'Please select atleast one facility'
      );
    }
  }
  transformData(allowedClients, allClient): TreeNode<string>[] {
    return allowedClients
      .map((matchingClient) => {
        const client = allClient.find(
          (c) => c.client_acronym === matchingClient.client_acronym
        );
        if (client) {
          return {
            label: matchingClient.client_name,
            data: client.client_acronym,
            dType: 'client',
            children: client.sites.map((site) => ({
              label: site.warehouse_name,
              data: site.facility_id,
              leaf: true,
              dType: 'warehouse',
            })),
          } as TreeNode;
        }
        return null;
      })
      .filter((client) => client !== null);
  }
  onNodeSelect(event) {
    const selectedNode = event.node;
    this.updateParentNodeState(selectedNode);
    // Find the parent node of the currently selected node
    let newParentNode = null;
    for (const node of this.nodes) {
      if (
        selectedNode === node ||
        node.children.some((child) => child === selectedNode)
      ) {
        newParentNode = node;
        break;
      }
    }
    // If a new parent node or its child is selected, deselect all previously selected nodes
    if (newParentNode) {
      const newSelectedNodes = this.selectedNodes.filter((node) => {
        return newParentNode.children.includes(node) || node === newParentNode;
      });
      // Clear all selections and then apply new selections
      this.selectedNodes = [...newSelectedNodes];
    }
    this.checkSelection();
    this.setClientNWarehouses();
  }
  onNodeUnselect(event) {
    const unselectedNode = event.node;
    this.selectedNodes = this.selectedNodes.filter(
      (node) => node !== unselectedNode
    );
    this.updateParentNodeState(unselectedNode);
    this.checkSelection();
    this.setClientNWarehouses();
  }
  updateParentNodeState(node: any) {
    if (node.parent) {
      const parentNode = node.parent;
      const allChildren = parentNode.children || [];
      const selectedChildren = allChildren.filter((child) =>
        this.selectedNodes.includes(child)
      );
      parentNode.partialSelected =
        selectedChildren.length > 0 &&
        selectedChildren.length < allChildren.length;
      parentNode.selected = selectedChildren.length === allChildren.length;
      // If the parentNode is partialSelected or fully selected, add it to selectedNodes if not already present
      if (parentNode.partialSelected || parentNode.selected) {
        if (!this.selectedNodes.includes(parentNode)) {
          this.selectedNodes.push(parentNode);
        }
      } else {
        // Remove parentNode from selectedNodes if it is neither partialSelected nor fully selected
        this.selectedNodes = this.selectedNodes.filter(
          (selectNode) => selectNode !== parentNode
        );
      }
      this.updateParentNodeState(parentNode); // Recursively update parent nodes
    }
  }
  getLabel(): string {
    if (this.selectedNodes.length === 0) {
      return 'Select Warehouse';
    }
    if (this.selectedNodes[0]?.children?.length > 0) {
      return `${this.selectedNodes[0].label} (${
        this.selectedNodes.length > 2
          ? 'Warehouses'
          : this.selectedNodes[0].children[0].label
      })`;
    }
    if (this.selectedNodes[1]?.children?.length > 0) {
      return `${this.selectedNodes[0].parent?.label || ''} (${
        this.selectedNodes.length > 2
          ? 'Warehouses'
          : this.selectedNodes[0].label || ''
      })`;
    }
    return `${this.selectedNodes[0].parent?.label || ''} (Warehouses)`;
  }
  /**
   * below code will store currently/newly selected client and warehouse in client portal service
   */
  setClientNWarehouses() {
    const selectedClient = this.selectedNodes.filter(
      (node) => node.dType === 'client'
    )[0]?.data;
    const selectedWarehouse = this.selectedNodes
      .filter((node) => node.dType === 'warehouse')
      .map((node) => node.data);
    if (selectedClient && selectedWarehouse) {
      this.clientWarehouseChange.emit({
        clientName: selectedClient,
        warehouseList: selectedWarehouse,
      });
    }
  }
}
