import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError, delay, of, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ClientPortalService } from '../clientPortalService/client-portal.service';
import { SnackBarService } from '../snackBar-services/snack-bar.service';
export const RCPInterceptor: HttpInterceptorFn = (req, next) => {
  const oAuthService = inject(OAuthService);
  const cpService = inject(ClientPortalService);
  const snackBar = inject(SnackBarService);
  const noExtraHeadersUrl = [
    environment.userConfigUrl + 'userdetails',
    environment.baseUrl + '/inventory/sites',
  ];
  if (req.url.includes(oAuthService.issuer)) {
    return next(req);
  }
  const accessToken = oAuthService.getAccessToken();
  let clonedRequest = req.clone({
    setHeaders: {
      Authorization: accessToken,
    },
  });
  if (!noExtraHeadersUrl.includes(req.url)) {
    clonedRequest = clonedRequest.clone({
      setHeaders: {
        cname: cpService.getActiveClient() || '',
        uuid: cpService.getLoggedInUserInfo()?.UUID || '',
        warehouses: cpService.getActiveWarehouses() || '',
      },
    });
  }
  return next(clonedRequest).pipe(
    tap((event) => {
      console.log(event);
    }),
    catchError((e: HttpErrorResponse) => {
      return handleError(e, oAuthService, snackBar, clonedRequest.url);
    })
  );
};
const handleError = (
  error: HttpErrorResponse,
  authService: OAuthService,
  snackBar: SnackBarService,
  reqUrl: string
) => {
  let errorMessage;
  if (error?.error instanceof ErrorEvent || error?.error?.statuscode === 400) {
    // Get client-side error
    errorMessage =
      error?.error?.message ||
      error?.error?.response?.errorMsg ||
      error?.error?.response;
    console.error('An client side error occured ', error);
  } else {
    // Get server-side error
    errorMessage = 'An server side error occured.';
    console.error('An server side error occured ', error);
  }
  if (error?.status === 401 || error?.status === 403) {
    console.log('loggin out because of ' + reqUrl, error);
    snackBar.showError(
      'Session Expired',
      'Your session has expired, please log in again.'
    );
    of(null)
      .pipe(delay(2000))
      .subscribe(() => {
        authService.revokeTokenAndLogout();
      });
  } else {
    snackBar.showError('API Error', errorMessage);
  }
  return throwError(() => {
    return error;
  });
};
