<div class="app-sidebar">
  <ul class="list">
    @if(modulesList){ @for (modules of modulesList; track $index) {
    @if(modules.module_group === reportingString){ @if(modules.children ||
    modules.children?.length >=0 ){
    <li
      [pTooltip]="reportingString"
      [tooltipDisabled]="!hideTooltip"
      tooltipPosition="right"
      placeholder="Right"
    >
      <span class="list-title">
        <i class="icon-report"></i>
        <span
          [ngClass]="displayTextAndIcon ? 'forward-motion' : 'backward-motion'"
          >{{ reportingString }}</span
        >
        <i *ngIf="displayTextAndIcon" class="fa fa-chevron-down"></i>
      </span>
      <ul
        [ngClass]="
          displayTextAndIcon ? 'visible-side-bar' : 'in-visible-side-bar'
        "
        class="list inner"
      >
        <div class="content">
          @for (childrens of modules.children; track $index) {
          @if(childrens?.module_name === realtimeDashboardString){
          <li (click)="goToRealtimedashoard()">
            {{ realtimeDashboardString }}
          </li>
          } @if(childrens?.module_name === historicDashboardString){
          <li (click)="goToHistoricDashboard()">
            {{ historicDashboardString }}
          </li>
          } @if(childrens?.module_name === reportBuilderString){
          <li (click)="goToReportBuilder()">{{ reportBuilderString }}</li>
          } }
        </div>
      </ul>
    </li>
    } } @if(modules.module_group === inboundString){ @if(modules.children ||
    modules.children?.length >=0 ){
    <li
      [pTooltip]="inboundString"
      [tooltipDisabled]="!hideTooltip"
      tooltipPosition="right"
      placeholder="Right"
    >
      <span class="list-title">
        <i class="icon-inbound"></i>
        <span
          [ngClass]="displayTextAndIcon ? 'forward-motion' : 'backward-motion'"
          >{{ inboundString }}</span
        >
        <i *ngIf="displayTextAndIcon" class="fa fa-chevron-down"></i>
      </span>
      <ul
        [ngClass]="
          displayTextAndIcon ? 'visible-side-bar' : 'in-visible-side-bar'
        "
        class="list inner"
      >
        <div class="content">
          @for (childrens of modules.children; track $index) {
          @if(childrens?.module_name === purchaseOrderDetailsString){
          <li (click)="goToInbound()">
            {{ purchaseOrderDetailsString }}
          </li>
          } }
        </div>
      </ul>
    </li>
    } } @if(modules.module_group === outboundSting){ @if(modules.children ||
    modules.children?.length >=0 ){
    <li
      [pTooltip]="outboundSting"
      [tooltipDisabled]="!hideTooltip"
      tooltipPosition="right"
      placeholder="Right"
    >
      <span class="list-title">
        <i
          class="icon-outbound"
          (click)="slideClose($event, displayTextAndIcon)"
        ></i>
        <span
          [ngClass]="displayTextAndIcon ? 'forward-motion' : 'backward-motion'"
          >{{ outboundSting }}</span
        >
        <i *ngIf="displayTextAndIcon" class="fa fa-chevron-down"></i>
      </span>
      <ul
        [ngClass]="
          displayTextAndIcon ? 'visible-side-bar' : 'in-visible-side-bar'
        "
        class="list inner"
      >
        <div class="content">
          @for (childrens of modules.children; track $index) {
          @if(childrens?.module_name === orderDetailsString){
          <li (click)="goToOrderDetails()">{{ orderDetailsString }}</li>
          } @if(childrens?.module_name === onTimeShipSLAString){
          <li (click)="goToSlaSummeryView()">{{ onTimeShipSLAString }}</li>
          } }
        </div>
      </ul>
    </li>
    } } @if(modules.module_group === returnsString){ @if(modules.children ||
    modules.children?.length >=0 ){
    <li
      [pTooltip]="returnsString"
      [tooltipDisabled]="!hideTooltip"
      tooltipPosition="right"
      placeholder="Right"
    >
      <span class="list-title">
        <i class="icon-return"></i>
        <span
          [ngClass]="displayTextAndIcon ? 'forward-motion' : 'backward-motion'"
          >{{ returnsString }}</span
        >
        <i *ngIf="displayTextAndIcon" class="fa fa-chevron-down"></i>
      </span>
      <ul
        [ngClass]="
          displayTextAndIcon ? 'visible-side-bar' : 'in-visible-side-bar'
        "
        class="list inner"
      >
        <div class="content">
          @for (childrens of modules.children; track $index) {
          @if(childrens?.module_name === returnDetailsString){
          <li (click)="goToReturn()">{{ returnDetailsString }}</li>
          } }
        </div>
      </ul>
    </li>
    } } @if(modules.module_group === inventoryString){ @if(modules.children ||
    modules.children?.length >=0 ){
    <li
      [pTooltip]="inventoryString"
      [tooltipDisabled]="!hideTooltip"
      tooltipPosition="right"
      placeholder="Right"
    >
      <span class="list-title">
        <i class="icon-inventory"></i>
        <span
          [ngClass]="displayTextAndIcon ? 'forward-motion' : 'backward-motion'"
          >{{ inventoryString }}</span
        >
        <i *ngIf="displayTextAndIcon" class="fa fa-chevron-down"></i>
      </span>
      <ul
        [ngClass]="
          displayTextAndIcon ? 'visible-side-bar' : 'in-visible-side-bar'
        "
        class="list inner"
      >
        <div class="content">
          @for (childrens of modules.children; track $index) {
          @if(childrens?.module_name === inventoryDetailsString){
          <li (click)="goToInventory()">{{ inventoryDetailsString }}</li>
          } }
        </div>
      </ul>
    </li>
    } } } }
  </ul>
  <div class="sidebar-btn-outer">
    <button
      class="sidebar-btn"
      [ngClass]="hideTooltip ? 'open-arrow' : 'close-arrow'"
      (click)="slideClose($event, displayTextAndIcon)"
      [pTooltip]="hideTooltip ? sidebarArrowOpen : sidebarArrowClose"
      tooltipPosition="right"
      placeholder="Right"
    ></button>
  </div>
</div>
