import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { delay, of } from 'rxjs';
import { ClientPortalService } from '../../services/clientPortalService/client-portal.service';
import { SnackBarService } from '../../services/snackBar-services/snack-bar.service';
/**
 * below function is used to check wheather the route
 * which user is trying to access is allowed for the active client
 * or not
 */
export const isRouteNavigationAllowed = (route: ActivatedRouteSnapshot, _) => {
  log('isRouteNavigationAllowed ' + route.routeConfig.path);
  const cpService = inject(ClientPortalService);
  const router = inject(Router);
  const routeUrl = route.routeConfig.path;
  if (!isValidToken()) {
    sessionExpired();
    return false;
  }
  if (cpService.permittedMenuItems) {
    if (cpService.permittedMenuItems.includes(`/${routeUrl}`)) {
      return true;
    } else {
      return router.createUrlTree([cpService.permittedMenuItems[0]]);
    }
  } else {
    return false;
  }
};
/**
 * below function is used to check wheather the route
 * which user is trying to access is allowed for active client
 * or not.
 * here we are checking againt the parent route as, as of now
 * we are having parent level access control only ,so if user is having
 * access to parent then all the inner child will also be accessible
 */
export const isChildRouteAllowed = (
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
) => {
  log('isChildRouteAllowed ' + route.routeConfig.path);
  const cpService = inject(ClientPortalService);
  const router = inject(Router);
  const parentRoute = route.data['parent_route'];
  if (!isValidToken()) {
    sessionExpired();
    return false;
  }
  if (cpService.permittedMenuItems) {
    if (cpService.permittedMenuItems.includes(`/${parentRoute}`)) {
      return true;
    } else {
      return router.createUrlTree([cpService.permittedMenuItems[0]]);
    }
  } else {
    return false;
  }
};
/**
 * below function is used to guard feature level access like additem,
 * change address etc.
 */
export const isFeatureAllowed = (
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
) => {
  log('isFeatureAllowed ' + route.routeConfig.path);
  const cpService = inject(ClientPortalService);
  const router = inject(Router);
  const acronym = route.data['acronym'];
  const activeClient = cpService.getActiveClient();
  if (!isValidToken()) {
    sessionExpired();
    return false;
  }
  if (cpService.isAcronymAllowed(activeClient, acronym)) {
    return true;
  } else {
    return router.createUrlTree([cpService.permittedMenuItems[0]]);
  }
};
const sessionExpired = () => {
  const snackBarService = inject(SnackBarService);
  const oAuthService = inject(OAuthService);
  snackBarService.showError(
    'Session Expired',
    'Your session has expired, please log in again'
  );
  of(null)
    .pipe(delay(2000))
    .subscribe(() => {
      oAuthService.revokeTokenAndLogout();
    });
};
const log = (msg) => {
  console.log('RouteGuards:::checking ', msg);
};
const isValidToken = (): boolean => {
  const oAuthService = inject(OAuthService);
  return oAuthService.hasValidAccessToken();
};
